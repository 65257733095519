body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Nunito Sans", "Open Sans",
    sans-serif;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #app, #app>div {
  height: 100%
}

#root{
  height: 100%;
  width: 100%;
}
